exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-standard-dla-mediow-tsx": () => import("./../../../src/templates/standard/dla-mediow.tsx" /* webpackChunkName: "component---src-templates-standard-dla-mediow-tsx" */),
  "component---src-templates-standard-dla-uczestnikow-tsx": () => import("./../../../src/templates/standard/dla-uczestnikow.tsx" /* webpackChunkName: "component---src-templates-standard-dla-uczestnikow-tsx" */),
  "component---src-templates-standard-home-tsx": () => import("./../../../src/templates/standard/home.tsx" /* webpackChunkName: "component---src-templates-standard-home-tsx" */),
  "component---src-templates-standard-sponsorzy-i-partnerzy-tsx": () => import("./../../../src/templates/standard/sponsorzy-i-partnerzy.tsx" /* webpackChunkName: "component---src-templates-standard-sponsorzy-i-partnerzy-tsx" */),
  "component---src-templates-standard-statystyki-tsx": () => import("./../../../src/templates/standard/statystyki.tsx" /* webpackChunkName: "component---src-templates-standard-statystyki-tsx" */),
  "component---src-templates-standard-terminy-przejazdow-tsx": () => import("./../../../src/templates/standard/terminy-przejazdow.tsx" /* webpackChunkName: "component---src-templates-standard-terminy-przejazdow-tsx" */)
}

