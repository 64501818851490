import React, { useEffect } from "react";

import {
  AppCtx,
  AppProvider,
  useActions,
  useContextState,
  WindowProvider,
} from "./components/contexted";
import { IAppActions, IAppState } from "./components/contexted/App/types";
import WholePageLoader from "./components/Layout/PageLoader";

interface ProviderProps {
  children: React.ReactNode;
}

interface ContextsWrapperProps {
  element: React.ReactNode;
}

const InnerProviders: React.FC<ProviderProps> = ({ children }) => {
  const { loaded } = useActions<IAppActions>(AppCtx, "loaded");
  const { imBusy } = useContextState<IAppState>(AppCtx, "imBusy");

  // useEffect(() => {
  //   const initTime = setTimeout(() => {
  //     if (imBusy) {
  //       loaded();
  //     }
  //   }, 2000);

  //   return () => {
  //     clearTimeout(initTime);
  //   };
  // });

  return (
    <>
      {/* {imBusy ? <WholePageLoader /> : null} */}
      {children}
    </>
  );
};

const OuterProviders: React.FC<ProviderProps> = ({ children }) => {
  return (
    <WindowProvider>
      <AppProvider>{children}</AppProvider>
    </WindowProvider>
  );
};

const ContextsWrapper: React.FC<ContextsWrapperProps> = ({
  element,
}): JSX.Element => (
  <OuterProviders>
    <InnerProviders>{element}</InnerProviders>
  </OuterProviders>
);

export default ContextsWrapper;
