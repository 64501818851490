import { IAppState } from "./types";

class AppActions {
  loaded = (state: IAppState) => ({
    ...state,
    imBusy: false,
  });

  loading = (state: IAppState) => ({
    ...state,
    imBusy: true,
  });

  changeIsModalOpen = (state: IAppState, payload: boolean) => ({
    ...state,
    isModalOpen: payload,
  });

  toggleIsModalOpen = (state: IAppState) => ({
    ...state,
    isModalOpen: !state.isModalOpen,
  });
}

const actions = new AppActions();
export default actions;
